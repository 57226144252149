.ms-skin-default .ms-slide .ms-slide-vpbtn, .ms-skin-default .ms-video-btn {
  width: 50px;
  height: 51px;
  background: url("light-skin-1.b6715e3b.png") -14px -150px no-repeat;
  margin: -25px 0 0 -25px;
  top: 50%;
  left: 50%;
}

.ms-skin-default .ms-slide .ms-slide-vcbtn {
  width: 30px;
  height: 30px;
  background: url("light-skin-1.b6715e3b.png") -24px -28px no-repeat;
  top: 18px;
  right: 30px;
}

.ms-skin-default .ms-nav-next, .ms-skin-default .ms-nav-prev {
  width: 30px;
  height: 40px;
  cursor: pointer;
  z-index: 110;
  background: url("light-skin-1.b6715e3b.png") -89px -26px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  left: 30px;
}

.ms-skin-default .ms-nav-next {
  background-position: -89px -103px;
  left: auto;
  right: 30px;
}

.ms-skin-default .ms-bullet {
  width: 10px;
  height: 10px;
  background: url("light-skin-1.b6715e3b.png") -34px -117px no-repeat;
  margin: 4px;
}

.ms-skin-default .ms-bullet-selected {
  background-position: -34px -84px;
}

.ms-skin-default .ms-time-bar {
  height: 3px;
  background: #fff;
  border-top: 1px solid #00000069;
}

.ms-skin-default .ms-tooltip {
  z-index: 210;
  cursor: auto;
  position: absolute;
}

.ms-skin-default .ms-tooltip-top .ms-tooltip-arrow, .ms-skin-default .ms-tooltip-bottom .ms-tooltip-arrow {
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  margin-left: -10px;
  position: absolute;
  left: 50%;
}

.ms-skin-default .ms-tooltip-top .ms-tooltip-arrow {
  border-top: 10px solid #f0f0f0e0;
  bottom: -10px;
}

.ms-skin-default .ms-tooltip-bottom .ms-tooltip-arrow {
  border-bottom: 10px solid #f0f0f0e0;
  top: -10px;
}

.ms-skin-default .ms-tooltip-right .ms-tooltip-arrow, .ms-skin-default .ms-tooltip-left .ms-tooltip-arrow {
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}

.ms-skin-default .ms-tooltip-right .ms-tooltip-arrow {
  border-right: 10px solid #f0f0f0e0;
  left: -10px;
}

.ms-skin-default .ms-tooltip-left .ms-tooltip-arrow {
  border-left: 10px solid #f0f0f0e0;
  right: -10px;
}

.ms-skin-default .ms-tooltip-point {
  cursor: pointer;
}

.ms-skin-default .ms-tooltip-point .ms-point-center {
  width: 12px;
  height: 12px;
  z-index: 105;
  background: #fff;
  border: 2px solid #bbb;
  border-radius: 15px;
  margin: -8px 0 0 -8px;
  position: absolute;
}

.ms-skin-default .ms-tooltip-point .ms-point-border {
  width: 22px;
  height: 22px;
  background: #0000005c;
  border-radius: 15px;
  margin: -11px 0 0 -11px;
  animation: 1.5s infinite point-anim;
  position: absolute;
}

.ms-skin-default .ms-tooltip {
  max-width: 200px;
}

.ms-skin-default .ms-tooltip-cont {
  text-shadow: 0 1px #fff;
  background: #f0f0f0e0;
  margin-left: -1px;
  padding: 10px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .ms-skin-default .ms-slide .ms-slide-vpbtn, .ms-skin-default .ms-video-btn, .ms-skin-default .ms-slide .ms-slide-vcbtn, .ms-skin-default .ms-nav-next, .ms-skin-default .ms-nav-prev, .ms-skin-default .ms-bullet {
    background-image: url("light-skin-1-retina.00abe6f1.png");
    background-size: 152px 225px;
  }
}

/*# sourceMappingURL=index.e50eb9f2.css.map */
